import { render, staticRenderFns } from "./App.vue?vue&type=template&id=86fa3762&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=86fa3762&prod&lang=css"
import style1 from "./App.vue?vue&type=style&index=1&id=86fa3762&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_prettier@1.19.1_react@18.3.1_vue-template_fr5sxhm2nlfgjr5xnbjovircru/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86fa3762",
  null
  
)

export default component.exports